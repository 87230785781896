import { App, createApp } from "vue";
import { mountApp } from "vue-app";
import DestinationsLandingPageComponent from "vue-components/pages/destinations-landing-page.vue";
import DestinationsComponent from "vue-components/pages/destinations.vue";

let app: App;
if (window.VIRTUOSO.destinationKey) {
    app = createApp(DestinationsComponent, { destinationKey: window.VIRTUOSO.destinationKey });
} else {
    app = createApp(DestinationsLandingPageComponent);
}

mountApp(app, "page-app");